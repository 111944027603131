<template>
  <div class="administration-role">
    <v-container fluid class="pa-6">
      <v-row style="position: relative;">
        <v-col md="12" lg="12">
          <div class="my-3 action-buttons">
            <v-btn  depressed class="bg-gradient" v-if="$func.hasRight('role/add')" @click="callForm('create', formCreateUrl)">
              <v-icon left dark small> $plus_l </v-icon>
              <b>{{ $t("addRole") }}</b>
            </v-btn>
          </div>
          <v-data-table :headers="headers" :items="roles" :height="datatableHeight - 110" fixed-header group-by="category" class="elevation-1 rounded-lg role-datatable" disable-sort disable-pagination hide-default-footer hide-default-header>
            <template v-slot:header="{ props: { headers } }">
              <thead class="v-data-table-header">
                <tr>
                  <th v-for="(header, index) in headers" v-bind:key="index" class="sortable">
                    <span>{{header.text}}</span>
                    <span v-if="index !== 0" class="pl-2 v-data-table-header__icon">
                      <v-icon small v-if="$func.hasRight('role/update')" @click="callForm('edit', formEditUrl + header.role_id)"> $pencil_l </v-icon>
                    </span>
                    <span v-if="index !== 0" class="pl-2 v-data-table-header__icon">
                      <v-icon small v-if="$func.hasRight('role/add')" @click="callForm('confirm', formDuplicateUrl + header.role_id)"> $copy_l </v-icon>
                    </span>
                    <span v-if="index !== 0" class="pl-2 v-data-table-header__icon">
                      <v-icon small v-if="$func.hasRight('role/delete')" @click="callForm('delete', formDeleteUrl + header.role_id)"> $trash_l </v-icon>
                    </span>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-for="(role, index) in rolesAndPermissions.roles" v-slot:[`item.${role}`]="{ item }">
              <v-simple-checkbox @input="toggleCheckbox(item[role], item, index)" v-model="item[role]" v-bind:key="role" ></v-simple-checkbox>
            </template>
            <template v-slot:[`group.header`]="data">
              <td>
                <v-icon :class="data.isOpen ? 'mr-2 expand-icon' : 'mr-2 expand-icon inactive'" size="15" @click="data.toggle">
                  $dropdown
                </v-icon>
                {{ data.group }}
              </td>
              <td v-for="(role, index) in rolesAndPermissions.roles" v-bind:key="role">
                <v-simple-checkbox @click="toggleAllCheckbox(data.items, role, index)" :value="checkboxGroupIsCheck(data.items, role)" ></v-simple-checkbox>
              </td>
            </template>
          </v-data-table>
        </v-col>
        <Loader v-if="$store.state.loader" :flat="false" />
      </v-row>

      <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :type="formType"
        :form="form"
        :action="'SET_DATA'"
      />
    </v-container>
  </div>
</template>

<script>
import { Ripple } from "vuetify/lib/directives";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins'
import refreshData from '@/mixins/mixins'
import Loader from '@/components/Loader'

export default {
  name: "AdministrationRoles",
  components: {
    FormDialogComponent,
    Loader,
  },
  directives: {
    Ripple,
  },
  mixins:[getForm, refreshData],
  data() {
    return {
      formUrl: "",
      formCreateUrl: "/role/getForm.fv2",
      formEditUrl: "/role/getForm.fv2?id=",
      formDuplicateUrl: "/role/getFormBeforeDuplicate?id=",
      formDeleteUrl: "/role/checkBeforeDelete?id=",
      form: null,
      apiUrl:"/role/getPermissions",
      mutation: "administration/setRolesAndPermissions",
      formType: "",
      roles: [],
      datatableHeight: 700
    };
  },
  mounted() {
    this.$store
      .dispatch("SET_LOADER", true)
      .then((e) => {
        setTimeout(() => {
          this.loadRoles();
          this.$store.dispatch("SET_LOADER", false);
        }, 300);
      });
  },
  created() {
    this.refreshData();
    if(document.getElementById('containerView') && document.getElementById('containerView').offsetHeight)
      this.datatableHeight = document.getElementById('containerView').offsetHeight
    
  },
  methods: {
    checkboxGroupIsCheck(items, ref) {
      let check = true;
      for (let key in items) {
        if (items[key][ref] === false || !items[key][ref]) check = false;
      }
      return check;
    },
    toggleCheckbox(isChecked, item, roleId) {
      this.setPermissionsAPI(roleId, [item.permission_id], isChecked);
    },
    toggleAllCheckbox(items, ref, roleId) {
      let checkboxGroupIsCheck = this.checkboxGroupIsCheck(items, ref);
      let permissionIds = [];

      for (let key in items) {
        items[key][ref] = !checkboxGroupIsCheck;
        permissionIds.push(items[key].permission_id);
      }

      this.setPermissionsAPI(roleId, permissionIds, !checkboxGroupIsCheck);
    },
    setPermissionsAPI(roleId, permissionIds, onoff) {
      this.$store.dispatch("SET_DATA", {
        url: "/role/" + roleId + "/setPermissions?onoff=" + onoff,
        data: permissionIds,
      });
    },
    callForm(formType, formUrl) {
      this.formType = formType;
      this.formUrl = formUrl;
      this.getForm(formUrl);
    },
    loadRoles() {
      let roles = [];

      if (this.rolesAndPermissions && this.rolesAndPermissions.permissions && this.rolesAndPermissions.roles_permissions) {
        for (let categoryKey in this.rolesAndPermissions.permissions) {
          for (let permissionKey in this.rolesAndPermissions.permissions[categoryKey]) {
            let permission = {
              permission: this.$t(permissionKey),
              permission_id: this.rolesAndPermissions.permissions[categoryKey][permissionKey],
              category: this.$t(categoryKey),
            };

            for (let roleKey in this.rolesAndPermissions.roles) {
              let isPermission = this.rolesAndPermissions.roles_permissions.find(
                (permission) =>
                  permission.role_id === roleKey &&
                  permission.permission_id === this.rolesAndPermissions.permissions[categoryKey][permissionKey]
              );

              permission[this.rolesAndPermissions.roles[roleKey]] = isPermission ? true : false;
            }

            roles.push(permission);
          }
        }
      }

      this.roles = roles;
    }
  },
  computed: {
    rolesAndPermissions: function () {
      return this.$store.state.administration.rolesAndPermissions;
    },
    headers() {
      var headers = [
        {
          text: "Permissions",
          value: "permission",
        },
      ];

      if (this.rolesAndPermissions && this.rolesAndPermissions.roles) {
        for (let key in this.rolesAndPermissions.roles) {
          headers.push({
            text: this.rolesAndPermissions.roles[key],
            value: this.rolesAndPermissions.roles[key],
            role_id: key,
          });
        }
      }
      
      setTimeout(() => {
        this.loadRoles();
      }, 300);

      return headers;
    },
  },
};
</script>
<style lang="scss">
.administration-role {
  .v-icon.v-icon:after { 
    display: none; 
  }
  .role-datatable {
    tbody {
      tr:hover:not(.v-row-group__header) {
        background-color: transparent !important;
      }
      tr:hover {
        cursor: inherit !important;
      }
    }
    .expand-icon.inactive {
      transform: rotate(-90deg);
    }
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
